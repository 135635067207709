<template>
  <div class="min-w-0 w-full">
    <!-- Basic statistics -->
    <v-row dense>
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-btn color="primary" v-if="accountsChanged" @click="updateStatistics">Päivitä</v-btn>

          <v-card-title>Yleiset</v-card-title>

          <v-card-text v-if="basicStatisticIsLoading" class="text-center">
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-card-text>

          <v-card-text v-show="!basicStatisticIsLoading">
            <basic-statistics ref="basicStatistics"></basic-statistics>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Talous -->
      <v-col cols="12" lg="6">
        <v-card class="pa-2 h-full">
          <v-card-title>Talous</v-card-title>

          <v-card-text v-if="invoiceStatisticIsLoading" class="text-center">
            <v-progress-circular
              class="justify-center"
              :size="60"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-card-text>

          <v-card-text v-show="!invoiceStatisticIsLoading">
            <invoice-statistics ref="invoiceStatistics"></invoice-statistics>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicStatistics from "@/components/GroupComponents/Overview/BasicStatistics";
import InvoiceStatistics from "@/components/GroupComponents/Overview/InvoiceStatistics";
import mixins from "@/mixins/mixins";
import { mapState, mapActions } from "vuex";

export default {
  title: "Yhteenveto",

  mixins: [mixins],

  components: {
    BasicStatistics,
    InvoiceStatistics,
  },

  data() {
    return {
      thisYear: new Date().getFullYear(),
      accountsChanged: false,
    };
  },

  computed: {
    ...mapState("statistics", ["basicStatisticIsLoading", "invoiceStatisticIsLoading"]),
    ...mapState("account", ["selectedAccounts", "selectedGroups"]),
  },

  watch: {
    selectedAccounts: {
      deep: true,
      handler() {
        this.accountsChanged = true;
      },
    },
    selectedGroups: {
      deep: true,
      handler() {
        this.accountsChanged = true;
      },
    },
  },

  methods: {
    ...mapActions("statistics", ["getStats"]),

    async updateStatistics() {
      try {
        await this.$refs.invoiceStatistics.getStatistics();
        await this.$refs.basicStatistics.getStatistics();
        this.accountsChanged = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
